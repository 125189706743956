import _ from 'lodash'
import moment from 'moment'
import { set, isThisMonth, format, startOfMonth, endOfMonth } from 'date-fns'
import { useState } from 'react'
import { formatInTimeZone } from 'date-fns-tz'
import toast from 'react-hot-toast'
import { toPng } from 'html-to-image'
import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import {
  breakDownDefaultOptions,
  eventDefaultOptions
} from '../Pages/Insights/Reports/Dropdowns/data'
import { customTrack } from './constants'

export const DATE_FORMAT = 'yyyy-MM-DD'
export const TOOLTIP_DATE_FORMAT = 'MMMM D, YYYY'

export function findLabelOption (options, payload) {
  for (const option of options) {
    const viewsOption = option.options.find((opt) => opt.value === payload)
    if (viewsOption) {
      return viewsOption
    }
  }
  return null
}

// open Intercom chat on click
export const openChat = () => {
  window.Intercom('show')
}

// format values
export const valueFormat = (data) => {
  if (data === 'NaN') return ''
  if (data) {
    return _.isInteger(data) ? data : parseFloat(data).toFixed(2)
  } else {
    return 0
  }
}

// title case
export const titleCase = (str, type = null) => {
  str = str.toString()
  if (type && type.toLowerCase() === 'meta_continent_code') { return str.toUpperCase() }
  if (type && type.toLowerCase() === 'meta_region') return str.toUpperCase()
  if (type && type.toLowerCase() === 'custom_user_id') return str
  if (type && type.toLowerCase() === 'user email') return str
  if (type && type.toLowerCase() === 'video source hostname') { return str.toLowerCase() }
  if (str.toLowerCase().startsWith('com.')) return str.toLowerCase()
  if (str.toLowerCase().startsWith('https')) return str
  if (str.toLowerCase().startsWith('http')) return str

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const convertMillisecondsToHours = (ms) => {
  const hours = ms / (1000 * 60 * 60)
  return hours
}

// millis conversion
export const millisToMinutesAndSeconds = (millis) => {
  if (millis === undefined) {
    return '-'
  }

  let seconds = Math.floor((millis / 1000) % 60)
  let minutes = Math.floor((millis / (1000 * 60)) % 60)
  let hours = Math.floor(millis / (1000 * 60 * 60))

  hours = hours > 0 ? hours + ' hr ' : ''
  minutes = minutes > 0 ? minutes + ' min ' : ''
  seconds = seconds > 0 && !(hours && minutes) ? seconds + ' sec ' : ''

  return hours || minutes || seconds ? hours + minutes + seconds : '-'
}

// make first letter capital
export const capitalizeFirstLetter = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

// convert degree to dms (for lat and long)
export const ConvertDEGToDMS = (deg, lat) => {
  const absolute = Math.abs(deg)

  const degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  const minutes = Math.floor(minutesNotTruncated)
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2)

  let direction

  if (lat) {
    direction = deg >= 0 ? 'N' : 'S'
  } else {
    direction = deg >= 0 ? 'E' : 'W'
  }

  return degrees + '°' + minutes + "'" + seconds + '"' + direction
}

// char limit
export const charLimit = (str) => {
  if (str.length > 40) {
    return (
      str.substr(0, 20) + '.....' + str.substr(str.length - 10, str.length)
    )
  }
  return str
}
export const titleLimit = (str) => {
  if (str.length > 40) {
    return str.substr(0, 30) + '...'
  }
  return str
}

export const formatBytes1000 = (bytes, decimals) => {
  if (!bytes || bytes === 0) return '0 Byte'
  const k = 1000 // or 1000 for binary
  const dm = decimals + 1 || 3
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const formatBytes = (bytes, decimals) => {
  if (!bytes || bytes === 0) return '0 Byte'
  const k = 1000 // or 1000 for binary
  const dm = decimals + 1 || 3
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
}

export const getMonthYearFromCreated = (created) => {
  const result = moment(created).format('yyyy-MM-DD')
  // gives month year in descending order since created date
  created = new Date(result)
  const currentDate = new Date()
  const months = []
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  let year = created.getFullYear()
  let month = created.getMonth()
  do {
    do {
      months.push(monthNames[month] + ' ' + year)
      month++
    } while (
      (year !== currentDate.getFullYear() && month < 12) ||
      month <= currentDate.getMonth()
    )
    month = 0
    year++
  } while (year <= currentDate.getFullYear())
  return months.reverse()
}

export const getMonthYearFromCurrentDate = () => {
  const currentDate = new Date()
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth()
  return monthNames[month] + ' ' + year
}

export const prepareQuery = async (options, req, topAssetsList) => {
  // Initialize date_range
  options.date_range = {}
  // Prioritize custom date range if provided and valid
  if (Array.isArray(req.custom) && req.custom.length === 2) {
    const [startDate, endDate] = req.custom

    // Validate and format the start date
    if (startDate && !isNaN(new Date(startDate).getTime())) {
      options.date_range.start_at = format(
        new Date(startDate),
        'yyyy-MM-dd'
      )
    }

    // Validate and format the end date
    if (endDate && !isNaN(new Date(endDate).getTime())) {
      options.date_range.end_at = format(new Date(endDate), 'yyyy-MM-dd')
    }
  } else if (req.monthYear) { // Use monthYear if custom date range is not provided
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const monthDate = set(new Date(), {
      month: months.indexOf(req.monthYear.split(' ')[0]),
      year: req.monthYear.split(' ')[1]
    })
    if (isThisMonth(monthDate)) {
      options.date_range.start_at = format(
        startOfMonth(new Date()),
        'yyyy-MM-dd'
      )
      options.date_range.end_at = format(new Date(), 'yyyy-MM-dd')
    } else {
      options.date_range.start_at = format(
        startOfMonth(monthDate),
        'yyyy-MM-dd'
      )
      options.date_range.end_at = format(
        endOfMonth(monthDate),
        'yyyy-MM-dd'
      )
    }
  } else if (!req.monthYear) {
    options.date_range.start_at = format(
      startOfMonth(new Date()),
      'yyyy-MM-dd'
    )
    options.date_range.end_at = format(new Date(), 'yyyy-MM-dd')
  }

  // If source is selected
  if (req.source && req.source !== 'all') {
    options.filters = {}
    options.filters.source_id = req.source
  }

  // If top_assets_list is provided
  if (topAssetsList) {
    options.top_assets_count = 100
  }

  return options
}

export const prepareQueryForImage = async (options, req, topAssetsList) => {
  // Initialize date_range
  options.date_range = {}
  // Prioritize custom date range if provided and valid
  if (Array.isArray(req.custom) && req.custom.length === 2) {
    const [startDate, endDate] = req.custom

    // Validate and format the start date
    if (startDate && !isNaN(new Date(startDate).getTime())) {
      options.date_range.start_at = format(
        new Date(startDate),
        'yyyy-MM-dd'
      )
    }

    // Validate and format the end date
    if (endDate && !isNaN(new Date(endDate).getTime())) {
      options.date_range.end_at = format(new Date(endDate), 'yyyy-MM-dd')
    }
  } else if (req.monthYear.custom_label) {
    options.date_range.start_at = moment(req.monthYear.value[0]).format(
      'YYYY-MM-DD'
    )

    options.date_range.end_at = moment(req.monthYear.value[1]).format(
      'YYYY-MM-DD'
    )
  } else if (req.monthYear) { // Use monthYear if custom date range is not provided
    options.date_range.start_at = moment(req.monthYear).format('YYYY-MM-DD')

    options.date_range.end_at = moment(req.monthYear).format('YYYY-MM-DD')
  }

  // If source is selected
  if (req.source && req.source !== 'all') {
    options.filters = {}
    options.filters.source_id = req.source
  }

  // If top_assets_list is provided
  if (topAssetsList) {
    options.top_assets_count = 100
  }

  return options
}

export const formatNumbers = (number) => {
  // Billions
  if (Math.abs(Number(number)) >= 1.0e9) {
    return (Math.abs(Number(number)) / 1.0e9).toFixed(2) + 'B'
  } else if (Math.abs(Number(number)) >= 1.0e6) {
    return (Math.abs(Number(number)) / 1.0e6).toFixed(2) + 'M'
  } else if (Math.abs(Number(number)) >= 1.0e3) {
    return (Math.abs(Number(number)) / 1.0e3).toFixed(2) + 'K'
  } else {
    return Math.abs(Number(number))
  }
}

export const formatDuration = (number, videoCms) => {
  const d = Number(number)
  const s = Math.floor(d % 60)
  const h = Math.floor(d / 3600)
  const m = videoCms ? Math.floor((d % 3600) / 60) : Math.ceil((d % 3600) / 60)

  const seconds = s >= 0 && s < 10 ? '0' + s : s >= 10 ? s : 0
  const minutes = m >= 0 && m < 10 ? '0' + m : m >= 10 ? m : 0
  const hours = h > 0 ? formatNumbers(h) : 0

  return {
    original: Math.ceil(Number(number) / 60), // sending total minutes for tooltip.
    hour: hours,
    minute: minutes,
    second: seconds
  }
}

export const showFilterCount = (paginationDetails) => {
  // make copy of paginationDetails
  const p1 = {
    ...paginationDetails
  }

  // delete unused object keys
  delete p1.page
  delete p1.limit
  delete p1.title
  delete p1.sortBy
  delete p1.orderBy
  delete p1.playlist

  // create and push objects in array
  const newData = Object.keys(p1).map((key) => ({
    key,
    value: p1[key]
  }))

  // remove object if it's value is empty
  const data = newData.filter((o) => o.value)

  return data
}

export function convertAndCalculatePercentage (usedSize, totalSize) {
  if (usedSize && totalSize) {
    const units = {
      B: 1,
      KB: 1024,
      MB: 1024 ** 2,
      GB: 1024 ** 3,
      TB: 1024 ** 4,
      PB: 1024 ** 5
    }

    const regexPattern = /(\d+(\.\d+)?)\s*([KMGTP]B?)?/g

    // Find all matches in the input strings
    const usedMatches = usedSize.matchAll(regexPattern)
    const totalMatches = totalSize.matchAll(regexPattern)

    // Initialize variables for total and used bytes
    let totalBytes = 0
    let usedBytes = 0

    // Iterate over matches and calculate total bytes
    for (const match of totalMatches) {
      const [, value, , unit] = match
      const floatValue = parseFloat(value)
      const byteSize = floatValue * (units[unit?.toUpperCase()] || 1)
      totalBytes += byteSize
    }

    // Iterate over matches and calculate used bytes
    for (const match of usedMatches) {
      const [, value, , unit] = match
      const floatValue = parseFloat(value)
      const byteSize = floatValue * (units[unit?.toUpperCase()] || 1)
      usedBytes += byteSize
    }

    // Calculate percentage
    const percentage = (usedBytes / totalBytes) * 100 || 0

    return Math.round(parseFloat(percentage))
  }
}

export const convertDataSize = (usedSize, totalSize) => {
  if (usedSize && totalSize) {
    const units = {
      B: 1,
      KB: 1024,
      MB: 1024 ** 2,
      GB: 1024 ** 3,
      TB: 1024 ** 4,
      PB: 1024 ** 5
    }

    // this function extracts the numeric values and units using regular expressions.
    // It converts the sizes to bytes and then calculates the percentage by dividing the used bytes
    // by the total bytes and multiplying by 100.

    const sizePattern = /^(\d+(\.\d+)?)\s*(\w+)$/i
    const [, usedValue, , usedUnit] = sizePattern.exec(usedSize)
    const [, totalValue, , totalUnit] = sizePattern.exec(totalSize)

    const usedBytes = parseFloat(usedValue) * units[usedUnit.toUpperCase()]
    const totalBytes = parseFloat(totalValue) * units[totalUnit.toUpperCase()]

    const percentage = (usedBytes / totalBytes) * 100

    return percentage
  }
}

export const calculateProgressBarWidth = (dataSize, quotaSize) => {
  const progress = (dataSize / quotaSize) * 100
  return progress
}

export const updateUrl = (
  paramsToUpdate,
  paramsToKeep,
  searchParams,
  setSearchParams
) => {
  // Set an additional parameter
  const updatedParams = {
    ...Object.fromEntries(searchParams.entries()),
    ...paramsToUpdate
  }

  // Filter out the parameters to keep
  const filteredParams = Object.entries(updatedParams).reduce(
    (acc, [key, value]) => {
      if (paramsToKeep.includes(key)) {
        acc[key] = value
      }
      return acc
    },
    {}
  )

  // Update the URL with the filtered parameters and the new parameters
  setSearchParams(filteredParams)
}

export const setOrDeleteParam = (paramName, value, searchParams) => {
  if (value || (Array.isArray(value) && value.length > 0)) {
    searchParams.set(paramName, value)
  } else {
    searchParams.delete(paramName)
  }
}

export const useCommonState = (paginationParams, pageLimit) => {
  const [paginationDetails, setPaginationDetails] = useState({
    page: paginationParams.page
      ? isNaN(paginationParams.page)
        ? 1
        : parseInt(paginationParams.page)
      : 1,
    limit:
      paginationParams.limit && !isNaN(paginationParams.limit)
        ? parseInt(paginationParams.limit)
        : pageLimit && !isNaN(pageLimit)
          ? parseInt(pageLimit)
          : 10,
    title: paginationParams.title ? paginationParams.title : '',
    tag: paginationParams.tag ? paginationParams.tag : '',
    status: paginationParams.status ? paginationParams.status : '',
    start_date: paginationParams.start_date ? paginationParams.start_date : '',
    end_date: paginationParams.end_date ? paginationParams.end_date : '',
    min_duration: paginationParams.min_duration
      ? paginationParams.min_duration
      : '',
    max_duration: paginationParams.max_duration
      ? paginationParams.max_duration
      : '',
    sortBy: paginationParams?.sortBy ? paginationParams.sortBy : '',
    orderBy: paginationParams?.orderBy ? paginationParams.orderBy : '',
    playlist: paginationParams?.playlist ? paginationParams.playlist : ''
  })

  return { paginationDetails, setPaginationDetails }
}

export const onErrorToastMessage = (err) => {
  return `${
    err?.response?.data?.error?.message +
    ' :- ' +
    err?.response?.data?.error?.param
  }`
}

export const datePickerRange = [
  {
    label: 'Today',
    value: [moment(), moment()],
    tooltip: moment().format(TOOLTIP_DATE_FORMAT)
  },
  {
    label: 'Yesterday',
    value: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    tooltip: moment().subtract(1, 'days').format(TOOLTIP_DATE_FORMAT)
  },
  {
    label: '7D',
    value: [moment().subtract(7, 'days'), moment()],
    tooltip:
      moment().subtract(7, 'days').format(TOOLTIP_DATE_FORMAT) +
      ' - ' +
      moment().format(TOOLTIP_DATE_FORMAT)
  },
  {
    label: '30D',
    value: [moment().subtract(30, 'days'), moment()],
    tooltip:
      moment().subtract(30, 'days').format(TOOLTIP_DATE_FORMAT) +
      ' - ' +
      moment().format(TOOLTIP_DATE_FORMAT)
  }
]

export const datePickerRangesForReport = [
  {
    label: 'Today',
    value: [moment(), moment()],
    custom_label: 'today',
    tooltip: moment().format(TOOLTIP_DATE_FORMAT)
  },
  {
    label: 'Yesterday',
    value: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    custom_label: 'yesterday',
    tooltip: moment().subtract(1, 'days').format(TOOLTIP_DATE_FORMAT)
  },
  {
    label: '7D',
    value: [moment().subtract(7, 'days'), moment()],
    custom_label: 'last_seven_days',
    tooltip:
      moment().subtract(7, 'days').format(TOOLTIP_DATE_FORMAT) +
      ' - ' +
      moment().format(TOOLTIP_DATE_FORMAT)
  },
  {
    label: '30D',
    value: [moment().subtract(30, 'days'), moment()],
    custom_label: 'last_thirty_days',
    tooltip:
      moment().subtract(30, 'days').format(TOOLTIP_DATE_FORMAT) +
      ' - ' +
      moment().format(TOOLTIP_DATE_FORMAT)
  }
]

export const datePickerRangesForUsageAnalytics = [
  {
    label: 'This Month',
    value: [moment().startOf('month'), moment().endOf('month')],
    custom_label: 'this_month'
  },
  {
    label: 'Last Month',
    value: [
      moment().subtract(1, 'months').startOf('month'),
      moment().subtract(1, 'months').endOf('month')
    ],
    custom_label: 'last_month'
  }
]

export const truncateUrl = (url, maxLength) => {
  if (url.length > maxLength) {
    return url.substring(0, maxLength - 3) + '...'
  } else {
    return url
  }
}

export const getMetricTooltip = (metric) => {
  let tooltip = ''

  switch (metric) {
    case 'views':
      tooltip = 'Total Views'
      break
    case 'unique_views':
      tooltip = 'Unique Users'
      break
    case 'playing_time':
      tooltip = 'Playing Time (hours)'
      break
    case 'concurrent_users':
      tooltip = 'Max Concurrent Users'
      break
    case 'player_startup_time':
      tooltip = 'Player Startup Time (ms)'
      break
    case 'startup_time':
      tooltip = 'Video Startup Time (ms)'
      break
    case 'seek_latency':
      tooltip = 'Seek Latency (ms)'
      break
    case 'rebuffer_percentage':
      tooltip = 'Rebuffer Percentage (%)'
      break
    case 'rebuffer_duration':
      tooltip = 'Rebuffer Duration (ms)'
      break
    case 'rebuffer_count':
      tooltip = 'Rebuffer Count (rebuffers)'
      break
    case 'rebuffer_frequency':
      tooltip = 'Rebuffer Frequency (rebuffers per minute)'
      break
    case 'playback_rate':
      tooltip = 'Playback Rate (%)'
      break
    case 'playback_failure_percentage':
      tooltip = 'Playback Failed (%)'
      break
    case 'upscale_percentage':
      tooltip = 'Upscaling (%)'
      break
    case 'downscale_percentage':
      tooltip = 'Bandwidth Wastage (%)'
      break
    case 'average_bitrate':
      tooltip = 'Average Bitrate (mbps)'
      break
    default:
      // Handle the case where metric does not match any known tooltip
      tooltip = 'Unknown Metric'
      break
  }

  return tooltip
}

export const getDaterange = (timeframe) => {
  switch (timeframe) {
    case 'today':
      return [moment().toDate(), moment().toDate()]
    case 'yesterday':
      return [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate()
      ]
    case 'last_seven_days':
      return [moment().subtract(7, 'days').toDate(), moment().toDate()]
    case 'last_thirty_days':
      return [moment().subtract(30, 'days').toDate(), moment().toDate()]
    case 'this_month':
      return [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate()
      ]
    case 'last_month':
      return [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate()
      ]
    default:
      return [moment().subtract(6, 'days').toDate(), moment().toDate()]
  }
}

// this helper class to convert utc epoch to date with spcific timezone
export const convertEpochToTimezone = (epoch, timezone, inputFormat) => {
  if (!epoch) return ''

  // if the timezone is not provided use the default bvrowser timezone
  const formateDatePattern = inputFormat || 'dd LLL yyyy, p'

  if (!timezone) {
    return format(new Date(epoch), formateDatePattern)
  }

  return formatInTimeZone(new Date(epoch), timezone, formateDatePattern)
}

/*
  This function is used to take a screenshot of the report graph and save it as a PNG file.
  It uses the html-to-image library to convert the report graph to a PNG data URL and then saves it as a file.
*/
export const handleScreenshot = (cardBodyRef, setExportReport, payload) => {
  if (cardBodyRef.current) {
    // Show loading toast
    const loadingToastId = toast.loading('Loading...')

    toPng(cardBodyRef.current)
      .then((dataUrl) => {
        saveAs(dataUrl, `${payload?.metrics[0]}.png`)
        // Dismiss loading toast
        toast.dismiss(loadingToastId)
        setExportReport(false)
        toast.success('Exported')
        customTrack('Report Exported', {
          report_type: 'PNG',
          filter: !!payload.filters.length,
          breakdown: !!payload.dimensions.length,
          type: payload.metrics[0]
        })
      })
      .catch((error) => {
        // Dismiss loading toast
        toast.dismiss(loadingToastId)
        setExportReport(false)
        toast.error(`Error: ${error.message}`)
      })
  }
}

/*
  This function is used to take a screenshot of the report graph and save it as a PDF file.
  It uses the html2canvas and jsPDF libraries to convert the report graph to a PDF file.
*/
export const handleSaveAsPdf = (saveGraphAsPDF, setExportReport, payload) => {
  if (saveGraphAsPDF.current) {
    // Show loading toast
    const loadingToastId = toast.loading('Exporting as PDF...')

    const getUpdatedMetrics = findLabelOption(
      eventDefaultOptions,
      payload.metrics[0]
    )
    const getDimension = findLabelOption(
      breakDownDefaultOptions,
      payload?.chart_dimension?.name
    )
    html2canvas(saveGraphAsPDF.current)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF() // eslint-disable-line new-cap
        const imgProps = pdf.getImageProperties(imgData)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()
        const imgWidth = pdfWidth
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width

        // Calculate the x and y coordinates for centering the image
        const x = (pdfWidth - imgWidth) / 2
        const y = (pdfHeight - imgHeight) / 2

        // Add text above the image
        const startDate = payload?.date_range?.start_at || ''
        const endDate = payload?.date_range?.end_at || ''
        const dimension = getDimension?.label || ''
        const title = `${getUpdatedMetrics?.label}${
          dimension ? ` by ${dimension}` : ''
        } :- ${startDate} - ${endDate}`

        const textX = pdfWidth / 2
        const textY = y - 10 // Position the text 10 units above the image
        pdf.setFontSize(16) // Set the font size
        pdf.text(title, textX, textY, { align: 'center' })

        pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight)
        pdf.save(`${payload?.metrics[0]}.pdf`)

        // Dismiss loading toast
        toast.dismiss(loadingToastId)
        setExportReport(false)
        toast.success('PDF exported')
        customTrack('Report Exported', {
          report_type: 'PDF',
          filter: !!payload.filters.length,
          breakdown: !!payload.dimensions.length,
          type: payload.metrics[0]
        })
      })
      .catch((error) => {
        // Dismiss loading toast
        toast.dismiss(loadingToastId)
        setExportReport(false)
        toast.error(`Error exporting as PDF: ${error.message}`)
      })
  }
}

export const colorCodes = [
  '#ff7f24',
  '#274e4b',
  '#31768d',
  '#8e7a9e',
  '#db6446',
  '#ff9e9e',
  '#8cbed6',
  '#ffa447',
  '#0f6c78',
  '#91b790'
]

export const isHourlyDisabled = (payload) => {
  const { start_at, end_at } = payload.date_range // eslint-disable-line camelcase
  const startDate = moment(start_at)
  const endDate = moment(end_at)
  const diffDays = endDate.diff(startDate, 'days')
  return diffDays !== 0
}

export const formatHHMMSSData = (dataArray) => {
  const hasHours = dataArray.some((time) => time.split(':').length === 3)
  return dataArray.map((time) => {
    const parts = time.split(':')

    // If it has hours, prepend "00:" to the minutes
    if (hasHours) {
      if (parts.length === 3) {
        // Format as hh:mm:ss
        return `${parts[0]}:${parts[1]}:${parts[2]}` // Keep hours as is
      } else {
        // Handle mm:ss format by adding "00:" to minutes
        return `00:${time}` // Prepend "00:" for minutes
      }
    } else {
      // If no hours, return as is
      return time
    }
  })
}

export const formatHHMMSSTime = (seconds) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  if (hours > 0) {
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
      remainingSeconds < 10 ? '0' : ''
    }${remainingSeconds}`
  } else {
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      remainingSeconds < 10 ? '0' : ''
    }${remainingSeconds}`
  }
}

export const exportTopAssets = (assets) => {
  const sortedSourceUsage = [...assets].sort(
    (a, b) => b.units - a.units
  )
  let csvContent = 'data:text/csv;charset=utf-8,' + 'asset,collection,bandwidth\n'

  sortedSourceUsage.forEach((item) => {
    const row = [item.asset_id, item.collection_name, formatBytes1000(item?.units)].join(',')
    csvContent += row + '\n'
  })

  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', 'top_assets.csv')
  document.body.appendChild(link)
  link.click()
}

export const convertSeconds = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  if (hours > 0) {
    return hours + ' hours'
  } else if (minutes > 0) {
    return minutes + ' minutes'
  }
  return '0 minutes'
}
export const findBreakDownLabelOption = (options, payload) => {
  if (
    !payload.chart_dimension.group_by ||
    payload.chart_dimension.group_by.length === 0
  ) {
    return []
  }
  // Find and return the matching options based on payload.chart_dimension
  const selectedOptions = payload?.chart_dimension?.group_by?.map(
    (dimension) => {
      // Find the group where the dimension name matches
      const matchedGroup = options.find((group) =>
        group.options.some((opt) => opt.value === dimension.name)
      )
      // If a matching group is found, return the option
      if (matchedGroup) {
        const matchedOption = matchedGroup.options.find(
          (opt) => opt.value === dimension.name
        )
        return matchedOption
      }

      return null
    }
  )

  // Filter out null values in case no match is found
  return selectedOptions.filter(Boolean)
}

export const getNextColor = (colorIndexRef) => {
  const color = colorCodes[colorIndexRef.current % colorCodes.length]
  colorIndexRef.current += 1
  return color
}

export const replaceEmptyKeys = (data) => {
  if (Array.isArray(data)) {
    // Filter out elements where x is "$overall"
    return data.filter((item) => item.x !== '$overall')
  } else if (typeof data === 'object' && data !== null) {
    // Process object keys
    return Object.keys(data).reduce((processed, key) => {
      if (key !== '$overall') {
        const newKey = key === '' ? '(not set)' : key
        processed[newKey] = replaceEmptyKeys(data[key])
      }
      return processed
    }, {})
  }

  return data
}

export const createChartEntry = (keys, data, colorSet, colorIndexRef) => {
  const color = getNextColor(colorIndexRef) || '#000000'
  const compositeKey = keys.join('||')
  if (!colorSet.has(color)) {
    colorSet.add(color)
    return { key: compositeKey, data, compositeKey, color }
  }
  return null
}

export const processBreakdownFilters = (
  filters = [], // Default to empty array
  filteredAnalyticsData,
  colorIndexRef
) => {
  return Array.isArray(filters) // Ensure filters is an array
    ? filters
      .map((filter) => {
        const keys = filter.split('||')
        const [level1Key, level2Key, level3Key] = keys
        const data = level3Key
          ? filteredAnalyticsData[level1Key]?.[level2Key]?.[level3Key]
          : level2Key
            ? filteredAnalyticsData[level1Key]?.[level2Key]
            : filteredAnalyticsData[level1Key]
        return data ? createChartEntry(keys, data, new Set(), colorIndexRef) : null
      })
      .filter(Boolean)
    : [] // Return an empty array if filters is not valid
}

export const accumulateAllOverall = (data) => {
  const map = new Map()

  function traverse (obj) {
    // Ensure the object is not null or undefined
    if (!obj || typeof obj !== 'object') return

    if (Array.isArray(obj.$overall)) {
      obj.$overall.forEach((item) => {
        if (map.has(item.x)) {
          map.get(item.x).y += item.y
        } else {
          map.set(item.x, { ...item })
        }
      })
    }

    for (const key in obj) {
      if (key !== '$overall') {
        traverse(obj[key])
      }
    }
  }

  traverse(data)

  return Array.from(map.values())
}

export const calculateDepth = (data) => {
  let depth = 0

  function getDepth (obj, currentDepth) {
    if (typeof obj === 'object' && obj !== null) {
      depth = Math.max(depth, currentDepth)
      for (const key in obj) {
        getDepth(obj[key], currentDepth + 1)
      }
    }
  }

  getDepth(data, 0)
  return depth
}

export const capitalizeWords = (str) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

export const calculateRowCount = (data) => {
  let rowCount = 0

  const traverseData = (item) => {
    if (item && typeof item === 'object' && !Array.isArray(item)) {
      Object.values(item).forEach((value) => {
        rowCount++
        traverseData(value)
      })
    }
  }

  if (data && typeof data === 'object') {
    Object.values(data).forEach((value) => {
      rowCount++
      traverseData(value)
    })
  }

  return rowCount
}

export const hasFormChanged = (formValues, sourceConfig) => {
  if (!sourceConfig) return false

  return _.some(formValues, (value, key) => {
    const sourceValue = sourceConfig[key]
    // Deep comparison for non-color fields
    return !_.isEqual(value, sourceValue)
  })
}

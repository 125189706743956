import React from 'react'

export default function DowngradeModal ({
  setOpenDowngradeModal,
  ConfirmDowngradePlan,
  plan,
  planType,
  orgData,
  onCloseModal,
  videoFree
}) {
  const openChat = () => {
    setOpenDowngradeModal(false)
    onCloseModal()
    window.Intercom('show')
  }
  return (
    <div className='modal-dialog'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{planType === 'video' ? 'Do you want to lose all the content?' : 'Your Customers Love Fast Platforms - Is this the right move?'}</h4>
          <button
            className='btn btn-xs btn-icon btn-ghost-danger rounded-circle'
            onClick={() => setOpenDowngradeModal(false)}
          >
            <i className='bi bi-x-lg' />
          </button>
        </div>
        <div className='modal-body'>
          {!videoFree
            ? (planType === 'video'
                ? <span className='fs-5'>
                  You are about to downgrade the plan to <b>{plan.plan}</b>. You
                  will lose the content stored on the{' '}
                  <b>{orgData && orgData.plan_data[planType].display_name}</b> plan.
                  If you are facing any issues, reach out to{' '}
                  <span className='text-primary' type='button' onClick={openChat}>
                    chat support.
                  </span>
                </span>
                : <span className='fs-5'>
                  You're about to switch to the <b>{plan.plan}</b> plan. Remember, <b>customers hate slow websites/apps</b> - make sure your website delivers the best experience. For personalized guidance, {' '}
                  <span className='text-primary' type='button' onClick={openChat}> reach out to support.
                  </span>
                </span>
              )
            : (
              <span className='fs-5'>
                You are about to downgrade the plan to <b>Free</b>. You will lose
                the content stored on the{' '}
                <b>{orgData && orgData.plan_data[planType].display_name}</b> plan.
                If you are facing any issues, reach out to{' '}
                <span className='text-primary' type='button' onClick={openChat}>
                  chat support.
                </span>
              </span>
              )}
        </div>
        <div className='modal-footer'>
          <div className='d-flex align-items-center justify-content-end gap-2'>
            <button
              className='btn btn-sm btn-white'
              onClick={() => ConfirmDowngradePlan(plan || 'free', orgData.plan_data[planType].display_name, planType)}
            >
              Downgrade
            </button>
            <button
              className='btn btn-sm btn-primary d-flex align-items-center'
              onClick={() => setOpenDowngradeModal(false)}
            >
              {planType === 'video' ? 'Save my content' : 'Keep Optimization'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect, Fragment, useRef } from 'react'
import http from 'utils/http'
import { endpoints } from 'global/endpoints'
import toast from 'react-hot-toast'
import StripeElement from 'components/StripeElement'
import {
  videoPriceSummaryData,
  imagePriceSummaryData
} from 'global/pricingSummary'
import { customTrack, videoFreeSummaryData } from 'utils/constants'
import NavbarTabLayout from 'components/NavbarTabLayout'
import SummaryCard from './SummaryCard'
import Video from './Video'
import Image from './Image'
import Footer from './Footer'
import Cookies from 'js-cookie'
import SubscriptionProcess from './SubscriptionProcess'
import { imagePricing, videoPricing } from '../../utils/constants'
import { useSearchParams } from 'react-router-dom'

export default function PricingModal ({
  onCloseModal,
  userData,
  plan,
  getPlanName,
  from,
  setFeedbackModal,
  theme
}) {
  const [toggle, setToggle] = useState(null)
  const [confirmedPlan, setConfirmedPlan] = useState({})
  const [summary, setSummary] = useState(false)
  const [showLastScreen, setLastScreen] = useState(false)
  const [stripe, setStripe] = useState(false)
  const [orgData, setOrgData] = useState({})
  const [apiResponse, setApiResponse] = useState({})
  const [cards, setCards] = useState([])
  const [coupon, setCoupon] = useState({})
  const [loading, setLoading] = useState(false)
  const [stripeLoading, setStripeLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(plan || 'video')
  const [searchParams, setSearchParams] = useSearchParams()

  const userPlan = Cookies.get('plan')
  const couponPlanName = searchParams.get('plan')
  const couponPlanPeriod = searchParams.get('period')
  useEffect(() => {
    getOrgData()
    getCouponData()

    const getCardData = async () => {
      try {
        const data = await http().get(endpoints.organization.billing.card.base)
        setCards(data.cards)
        if (userPlan || userData?.signupWithPlan || (couponPlanName && couponPlanPeriod)) {
          // when user is coming from website will get user_plan variable from
          // cookie and do the below things
          if (
            userPlan?.startsWith('image') ||
            userData?.signupWithPlan?.startsWith('image') ||
            couponPlanName.startsWith('image')
          ) {
            let getImagePlanObject
            setActiveTab('image')
            const selectedPlan = couponPlanName || userPlan || userData?.signupWithPlan

            if (selectedPlan) {
              const planName = selectedPlan.split('_')[1]
              getImagePlanObject = imagePricing.plans.find((img) => img.plan.toLowerCase() === planName)
            }
            onPlanSelection(getImagePlanObject, '', 'image', data.cards)
          } else {
            let getVideoPlanObject
            setActiveTab('video')

            const selectedPlan = couponPlanName || userPlan || userData?.signupWithPlan

            if (selectedPlan) {
              const planName = selectedPlan.split('_')[1]

              if (selectedPlan.startsWith('video_free')) {
                getVideoPlanObject = videoFreeSummaryData.find((vid) => vid.plan.toLowerCase() === planName)
              } else {
                getVideoPlanObject = videoPricing.plans.find((vid) => vid.plan.toLowerCase() === planName)
              }
            }
            onPlanSelection(getVideoPlanObject, '', 'video', data.cards)
          }
        }
      } catch (error) {
        console.log(error)
        toast.error(error?.error?.message)
      }
    }

    if (getPlanName) {
      // when user is coming from internally
      if (getPlanName === 'image') {
        setActiveTab('image')
      } else {
        setActiveTab('video')
      }
    }
    getCardData()
  }, [userPlan, userData, getPlanName, couponPlanName, couponPlanPeriod])

  const getCouponData = async () => {
    try {
      const data = await http().get(endpoints.organization.coupon.base)
      const filteredCoupon = data.coupons.find(
        (coupon) => coupon.origin === 'stripe'
      )
      setCoupon(filteredCoupon)
    } catch (error) {
      toast.error(error?.error?.message)
    }
  }

  const getOrgData = async () => {
    try {
      const data = await http().get(endpoints.org)
      setOrgData(data)

      if (data.plan_cycle === 'yearly') {
        setToggle(true)
      } else if (data.plan_cycle === 'monthly') {
        setToggle(false)
      }
    } catch (error) {
      toast.error(error?.error?.message)
    }
  }

  const handleYearlyPriceToggle = (e) => {
    const { checked } = e.target
    setToggle(checked)
  }

  const onPlanSelection = (item, trial, type, cardsFrom, downgrade) => {
    if ((cards && cards.length > 0) || (cardsFrom && cardsFrom.length > 0)) {
      setSummary(true)
    } else {
      setStripe(true)
    }
    const confirmedPlanPayload = {}
    confirmedPlanPayload.plans = item.plan
    confirmedPlanPayload.discount = toggle || couponPlanPeriod === 'yearly' ? item.yearlyPrice : item.price
    confirmedPlanPayload.price = parseInt(item.price.replace('$', ''))
    confirmedPlanPayload.yearly = parseInt(item.yearlyPrice.replace('$', ''))
    confirmedPlanPayload.is_yearly = toggle || couponPlanPeriod === 'yearly'
    confirmedPlanPayload.type = type
    confirmedPlanPayload.plan = item.id
    confirmedPlanPayload.trial = trial && true
    confirmedPlanPayload.downgrade_details = downgrade
    setConfirmedPlan(confirmedPlanPayload)
  }
  const onStripeBack = () => {
    setStripe(false)
  }

  const getRemanningDays = function () {
    const date = new Date()
    const time = new Date(date.getTime())
    time.setMonth(date.getMonth() + 1)
    time.setDate(0)
    const days =
      time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0
    return days + 1
  }

  const getDaysInMonth = function () {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  const getDiscountedProratedPrice = (monthly_price, yearly, is_yearly) => {
    let price
    if (is_yearly) {
      price = yearly * 12
    } else {
      price = ((monthly_price * getRemanningDays()) / getDaysInMonth()).toFixed(
        2
      )
    }

    if (isNaN(price)) {
      price = 0
    }
    return '$' + price
  }

  const getProratedPrice = (monthly_price, is_yearly) => {
    let price

    if (is_yearly) {
      price = monthly_price * 10
    } else {
      price = ((monthly_price * getRemanningDays()) / getDaysInMonth()).toFixed(
        2
      )
    }

    if (isNaN(price)) {
      price = 0
    }
    return '$' + price
  }

  const onConfirmPlan = async () => {
    const payload = {
      plan: confirmedPlan.plan,
      plan_type: confirmedPlan.type,
      plan_cycle: confirmedPlan.is_yearly ? 'yearly' : 'monthly'
    }

    setLoading(true)
    try {
      const data = await http().post(
        endpoints.organization.billing.plan,
        payload
      )
      if (data) {
        if (data.invoice_status) {
          setLoading(false)
          setApiResponse(data)
          setLastScreen(true)
          if (!confirmedPlan.downgrade) {
            window.dataLayer.push({
              'customerEmail': userData?.email // eslint-disable-line
            })
            window.dataLayer.push({
              event: 'purchase',
              ecommerce: {
                value: confirmedPlan.is_yearly ? confirmedPlan.price : confirmedPlan.price * 12,
                currency: 'USD',
                transaction_id: data?.payment_id ? data?.payment_id : ''
              }
            })
          }
        } else if (data.success) {
          setLoading(false)
          if (!confirmedPlan.downgrade) {
            window.dataLayer.push({
              'customerEmail': userData?.email // eslint-disable-line
            })
            window.dataLayer.push({
              event: 'purchase',
              ecommerce: {
                value: confirmedPlan.is_yearly ? confirmedPlan.price : confirmedPlan.price * 12,
                currency: 'USD',
                transaction_id: data?.payment_id
              }
            })
          } else {
            customTrack('downgrade_initiated', {
              current_plan_name: confirmedPlan?.downgrade_details?.currentPlan.plan,
              old_plan_name: confirmedPlan?.downgrade_details?.existingPlan
            })
          }
          // Once a plan is purchased successfully, we need to load the tag in DOM
          const img = window.document.createElement('img')
          img.src =
            'https://tracking.g2crowd.com/funnels/506aa557-a76e-4a07-95cd-f73d0fac3351.gif?stage=finish&stype=offer'
          img.alt = 'tracking_image'
          // Append the image to the body
          window.document.body.appendChild(img)
          onCloseModal()
          if (!confirmedPlan.downgrade) {
            setFeedbackModal(confirmedPlan)
          }
        } else {
          setLoading(false)
          window.location.reload()
        }
      }
    } catch (error) {
      setLoading(false)
      setLastScreen(false)
      toast.error(error?.error?.message || error?.message)
      customTrack('Payment failed', {
        value: confirmedPlan.is_yearly ? confirmedPlan.price : confirmedPlan.price * 12,
        currency: 'USD',
        error: error?.error?.message || error?.message
      })
    }
  }

  const stripeElementRef = useRef(null)

  const onSaveCard = () => {
    // Call handleSubmit function from StripeElement
    if (stripeElementRef.current) {
      stripeElementRef.current.handleSubmitFromCardForm()
    } else {
      console.error('Stripe Element reference is not set')
    }
  }

  const onBack = () => {
    setSummary(false)
  }

  const videoFree = {
    id: 'videoPlan1',
    plan: 'free',
    price: 'free',
    yearlyPrice: 'free',
    title: 'For personal projects',
    details: []
  }

  const finalSummaryData = videoPriceSummaryData.find(
    (item) => item.plan === confirmedPlan.plans
  )

  const finalImageSummaryData = imagePriceSummaryData.find(
    (item) => item.plan === confirmedPlan.plans
  )

  const finalFreeVideoSummaryData = videoFreeSummaryData.find(
    (item) => item.plan === confirmedPlan.plans
  )

  // TAB LIST DATA //
  const tabList = [
    {
      name: 'video',
      id: 'video',
      label: 'Video',
      icon: 'bi-play-btn',
      content: (
        <Video
          summary={summary}
          stripe={stripe}
          onPlanSelection={onPlanSelection}
          orgData={orgData}
          toggle={toggle}
          videoFree={videoFree}
          planType='video'
          handleYearlyPriceToggle={handleYearlyPriceToggle}
          coupon={coupon}
          userData={userData}
          onCloseModal={onCloseModal}
        />
      )
    },
    {
      name: 'image',
      id: 'image',
      label: 'Image',
      icon: 'bi-images',
      content: (
        <Image
          summary={summary}
          stripe={stripe}
          onPlanSelection={onPlanSelection}
          orgData={orgData}
          toggle={toggle}
          videoFree={videoFree}
          planType='image'
          handleYearlyPriceToggle={handleYearlyPriceToggle}
          coupon={coupon}
          userData={userData}
          onCloseModal={onCloseModal}
        />
      )
    }
  ]

  return (
    <div className='modal-content'>
      <div className='modal-header pt-3'>
        <h2 className='modal-title'>
          {!summary && !stripe ? 'Select Plan' : ''}
        </h2>
        <button className='btn btn-icon btn-sm' onClick={onCloseModal}>
          <i className='tio-clear tio-lg' />
        </button>
      </div>
      <div className='modal-body pt-0'>
        {showLastScreen ? (
          <SubscriptionProcess
            apiResponse={apiResponse}
            activeTab={activeTab}
          />
        ) : summary ? (
          // ## SUMMARY CARD ##
          <SummaryCard
            finalSummaryData={finalSummaryData}
            finalFreeVideoSummaryData={finalFreeVideoSummaryData}
            finalImageSummaryData={finalImageSummaryData}
            confirmedPlan={confirmedPlan}
            toggle={toggle}
            getProratedPrice={getProratedPrice}
            getDiscountedProratedPrice={getDiscountedProratedPrice}
            coupon={coupon}
          />
        ) : stripe ? (
          <StripeElement
            setSummary={setSummary}
            setStripe={setStripe}
            setStripeLoading={setStripeLoading}
            orgData={orgData}
            stripeElementRef={stripeElementRef}
            confirmedPlan={confirmedPlan}
            coupon={coupon}
            userData={userData}
            theme={theme}
          />
        ) : (
          // ## Pricing Plan Content ##
          <>
            <div className='py-3'>
              {from
                ? (
                  <></>
                  )
                : (
                  <NavbarTabLayout
                    tabData={tabList}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  )}
            </div>
            {tabList.map((tab, i) => {
              if (tab.id === activeTab) {
                return <Fragment key={i}>{tab.content}</Fragment>
              } else {
                return <></>
              }
            })}
          </>
        )}
      </div>
      {/* //  ## Modal Footer ## */}

      {(stripe || (summary && !showLastScreen)) && (
        <Footer
          stripe={stripe}
          stripeLoading={stripeLoading}
          onStripeBack={onStripeBack}
          onSaveCard={onSaveCard}
          summary={summary}
          onBack={onBack}
          loading={loading}
          onConfirmPlan={onConfirmPlan}
        />
      )}
    </div>
  )
}
